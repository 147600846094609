<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->
  <div class="custom-container">
    <!--    Page Title-->
    <page-title>
      <template #title>
        <span class="h4 text-primary">New Emergency Contact</span>
      </template>
    </page-title>

    <div class="row pt-2">
      <div class="col-12">
        <Form @submit="save" v-slot="meta" :validation-schema="schema">
          <!--            Row 1: First Name & Last Name-->
          <div class="row">
            <div class="col-xs-12 col-md-6 form-group">
              <label>First name</label>
              <input-field icon="id-card">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    id="firstName"
                    :class="meta.errors.firstName ? 'is-invalid' : ''"
                    v-model="emergencyContact.firstName"
                  />
                  <ErrorMessage name="firstName" class="invalid-feedback" />
                </template>
              </input-field>
            </div>

            <div class="col-xs-12 col-md-6 form-group">
              <label>Last name</label>
              <input-field icon="id-card">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    id="lastName"
                    :class="meta.errors.lastName ? 'is-invalid' : ''"
                    v-model="emergencyContact.lastName"
                  />
                  <ErrorMessage name="lastName" class="invalid-feedback" />
                </template>
              </input-field>
            </div>
          </div>

          <!--            Row 2: Gender & Relationship-->
          <div class="row">
            <div class="col-xs-12 col-md-6 form-group">
              <label>Gender</label>

              <Dropdown
                size="lg"
                :options="gender"
                v-model="emergencyContact.gender"
                placeholder="Please select gender"
                icon="venus-mars"
                :input-class="meta.errors.gender ? 'is-invalid' : ''"
                :fields="['id', 'type']"
              />

              <!--            Extra Field - hidden for validation-->
              <Field
                name="gender"
                id="gender"
                :class="meta.errors.gender ? 'is-invalid' : ''"
                type="hidden"
                v-model="emergencyContact.gender"
              />
              <ErrorMessage name="gender" class="invalid-feedback" />
            </div>
            <div class="col-xs-12 col-md-6 form-group">
              <label>Relationship</label>
              <input-field icon="user">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="relationship"
                    placeholder="Relationship"
                    id="relationship"
                    :class="meta.errors.relationship ? 'is-invalid' : ''"
                    v-model="emergencyContact.relationship"
                  />
                  <ErrorMessage name="relationship" class="invalid-feedback" />
                </template>
              </input-field>
            </div>
          </div>

          <!--            Row 3: Work Phone & Mobile Number-->
          <div class="row">
            <div class="col-xs-12 col-md-6 form-group">
              <label>Work Phone</label>
              <input-field icon="phone-alt">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="workPhone"
                    id="workPhone"
                    placeholder="Work Number"
                    :class="meta.errors.workPhone ? 'is-invalid' : ''"
                    v-model="emergencyContact.workPhone"
                  />
                  <ErrorMessage name="workPhone" class="invalid-feedback" />
                </template>
              </input-field>
            </div>

            <div class="col-xs-12 col-md-6 form-group">
              <label>Mobile Phone</label>
              <input-field icon="phone-alt">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="mobilePhone"
                    id="mobilePhone"
                    placeholder="Mobile Number"
                    :class="meta.errors.mobilePhone ? 'is-invalid' : ''"
                    v-model="emergencyContact.mobilePhone"
                  />
                  <ErrorMessage name="mobilePhone" class="invalid-feedback" />
                </template>
              </input-field>
            </div>
          </div>

          <!--            Row 4: Home phone & Email-->
          <div class="row">
            <div class="col-xs-12 col-md-6 form-group">
              <label>Home Phone</label>
              <input-field icon="phone-alt">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="homePhone"
                    id="homePhone"
                    placeholder="Home Number"
                    :class="meta.errors.homePhone ? 'is-invalid' : ''"
                    v-model="emergencyContact.homePhone"
                  />
                  <ErrorMessage name="homePhone" class="invalid-feedback" />
                </template>
              </input-field>
            </div>

            <div class="col-xs-12 col-md-6 form-group">
              <label>Email Address</label>
              <input-field icon="at">
                <template v-slot:input>
                  <Field
                    type="text"
                    id="emailAddress"
                    name="emailAddress"
                    placeholder="Email Address"
                    :class="meta.errors.emailAddress ? 'is-invalid' : ''"
                    v-model="emergencyContact.emailAddress"
                  />
                  <ErrorMessage name="emailAddress" class="invalid-feedback" />
                </template>
              </input-field>
            </div>
          </div>

          <!--        Buttons-->
          <div class="row mb-5 mt-3 justify-content-center">
            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="button"
                class="btn-secondary btn-lg-cus"
                @click.prevent="this.$router.back()"
              >
                Cancel
              </button>
            </div>

            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="submit"
                class="btn-primary btn-lg-cus"
                :disabled="loading"
              >
                <span> Save </span>
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div
                v-if="message"
                v-html="message"
                class="alert alert-danger small"
              ></div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <!--  Modal-->
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script src="./new-emergency-contact.ts" />

<style scoped></style>
